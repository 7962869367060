import { RequestMetadata, RequestResponse } from '../../apiConfig';
import { getRequest } from '../../RequestFactory';
import { APIOrder } from 'types/order';

const GET_ORDER_BY_ID: RequestMetadata = {
	path: '/Orders/{id}',
	method: 'GET',
	headers: {},
	shouldAuth: true,
	hasResponseBody: true,
	isDataServer: false,
	isLolApi: false,
};

type GetOrderByIdResponseBody = APIOrder;

export const getOrderById = async (id: string): Promise<RequestResponse<GetOrderByIdResponseBody>> => {
	return await getRequest<undefined, GetOrderByIdResponseBody>(GET_ORDER_BY_ID, {
		path: new Map<string, string>([['id', `${id}`]]),
	});
};

export default getOrderById;
