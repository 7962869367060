import { RequestMetadata, RequestResponse } from '../../apiConfig';
import { getRequest } from '../../RequestFactory';
import { ConfirmPaymentRequestBody, MessageResponseBody } from 'types/order';

const CONFIRM_PAYMENT: RequestMetadata = {
	path: '/payments/pay',
	method: 'PUT',
	headers: { 'Content-Type': 'application/json' },
	shouldAuth: true,
	hasResponseBody: false,
	isDataServer: false,
	isLolApi: false,
};

export const confirmPayment = async (
	orderId: string,
	providerPaymentId: string,
): Promise<RequestResponse<MessageResponseBody>> => {
	return await getRequest<ConfirmPaymentRequestBody, MessageResponseBody>(CONFIRM_PAYMENT, {
		body: {
			orderId,
			providerPaymentId,
		},
	});
};

export default confirmPayment;
