import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dispatchWithOrderPriceCalculation, setStartingLP } from '../../../redux/actions/paymentActions';
import { AppState, ThunkGlobalDispatch } from '../../../redux/root';
import { BaseRangeMultiplier, OrderParamsProps } from '../../types/general';
import ServicesMultiplierDropdown from '../UI/ServicesMultiplierDropdown';

interface ParamsStartingLpProps extends OrderParamsProps {}

const ParamsStartingLp: React.FC<ParamsStartingLpProps> = ({ tiersPricing, value, paramPricing }) => {
	const thunkDispatch = useDispatch<ThunkGlobalDispatch>();
	const { rankFrom } = useSelector((state: AppState) => state.payment);
	const { numberOfMasterPoints } = useSelector((state: AppState) => state.general);

	const mastersData = rankFrom?.name === 'Master' ? numberOfMasterPoints : undefined;

	return (
		<div className="dropdowns--starting-lp">
			<div className="params-header">
				<span>03</span>
				<h2>Starting LP</h2>
			</div>
			<ServicesMultiplierDropdown
				mastersData={mastersData}
				handleRangeOptionClick={(multiplier: BaseRangeMultiplier) => {
					thunkDispatch(dispatchWithOrderPriceCalculation(setStartingLP(multiplier), tiersPricing));
				}}
				value={value}
				options={paramPricing}
			/>
		</div>
	);
};

export default ParamsStartingLp;
