import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ORDER_TYPES } from '../../../constants/general';
import { loadBackdrop } from '../../../redux/actions/generalActions';
import { dispatchWithOrderPriceCalculation, setIsPriority } from '../../../redux/actions/paymentActions';
import { AppState, ThunkGlobalDispatch } from '../../../redux/root';
import { PayPerGamePricing, TiersPricing } from 'types/general';
import Button from 'components/shared/Button';
import RolesPicker from '../UI/RolesPicker';

const ExtrasSection: React.FC = () => {
	const thunkDispatch = useDispatch<ThunkGlobalDispatch>();
	const [isActive, setIsActive] = useState<boolean>();
	const { tiersPricing, netWinsPricing, placementPricing, isPriority, orderType } = useSelector(
		(state: AppState) => state.payment,
	);

	const { selectedRoles } = useSelector((state: AppState) => state.general);

	const assignPricingSource = () => {
		switch (orderType?.toLocaleUpperCase()) {
			case ORDER_TYPES.LEAGUE:
			case ORDER_TYPES.DUO: {
				if (tiersPricing) {
					return tiersPricing;
				}
			}
			case ORDER_TYPES.NETWINS: {
				if (netWinsPricing) {
					return netWinsPricing;
				}
			}
			case ORDER_TYPES.PLACEMENT: {
				if (placementPricing) {
					return placementPricing;
				}
			}
		}
	};
	const pricing: TiersPricing | PayPerGamePricing | undefined = assignPricingSource();

	const handleRolesPickerClose = () => {
		if (selectedRoles && (selectedRoles.length === 0 || selectedRoles?.length > 1)) {
			return setIsActive(false);
		}
		return toast.error('Select more than 1 role or discard selection');
	};

	return (
		<div className="extras">
			<div className="extras__option">
				<p>Priority order:</p>
				<label className="input-container">
					<input
						checked={isPriority}
						onChange={() => {
							thunkDispatch(dispatchWithOrderPriceCalculation(setIsPriority(!isPriority), pricing));
						}}
						type="checkbox"
					/>
					<span className="checkmark"></span>
				</label>
			</div>
			<div className="extras__option">
				<p>Specific champions:</p>
				<Button
					trigger={() => {
						thunkDispatch(loadBackdrop(true));
						document.body.style.overflow = 'hidden';
					}}
					type="button"
					variant="extras"
				>
					Pick champs
				</Button>
			</div>
			<div className="extras__option">
				<p>Specific roles:</p>
				<Button trigger={() => setIsActive(!isActive)} type="button" variant="extras">
					Pick roles
				</Button>
				<RolesPicker
					handleRolesPickerClose={() => handleRolesPickerClose()}
					handleClick={() => setIsActive(false)}
					isActive={isActive}
				/>
			</div>
		</div>
	);
};

export default ExtrasSection;
