import { ValidateResetTokenRequestBody, ValidateResetTokenResponseBody } from 'types/auth';
import { RequestMetadata, RequestResponse } from '../../apiConfig';
import { getRequest } from '../../RequestFactory';

const AUTH_VALIDATE_RESET_TOKEN: RequestMetadata = {
	path: '/Accounts/validate-reset-token',
	headers: { 'Content-Type': 'application/json' },
	method: 'POST',
	shouldAuth: false,
	hasResponseBody: true,
	isDataServer: false,
	isLolApi: false,
	credentials: true,
};

export const authValidateResetToken = async (
	token: string,
): Promise<RequestResponse<ValidateResetTokenResponseBody>> => {
	return await getRequest<ValidateResetTokenRequestBody, ValidateResetTokenResponseBody>(AUTH_VALIDATE_RESET_TOKEN, {
		body: {
			token,
		},
	});
};

export default authValidateResetToken;
