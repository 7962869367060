import React, { useEffect, useState } from 'react';
import BrandCover from 'components/shared/BrandCover';
import { PayPalButton } from 'react-paypal-button-v2';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkGlobalDispatch, AppState } from '../../redux/root';
import { Link, RouteComponentProps } from '@reach/router';
import { confirmPayment, getOrderById } from '../../api/requests/requestHandler';
import { APIOrder } from 'types/order';
import Spinner from 'components/shared/Spinner';
import { loadLoadingScreen } from '../../redux/actions/generalActions';
import { toast } from 'react-toastify';
import LayoutRoot from 'components/shared/LayoutRoot';
import { ORDER_STATUS } from '../../constants/general';

interface IPaymentProps extends RouteComponentProps {
	orderId?: string;
}

const Payment: React.FC<IPaymentProps> = ({ orderId }) => {
	const thunkDispatch = useDispatch<ThunkGlobalDispatch>();
	const [orderDetails, setOrderDetails] = useState<APIOrder>();
	const [isSuccessfull, setIsSuccessfull] = useState<boolean>(false);
	const { isLoading, isBackdropActive } = useSelector((state: AppState) => state.general);
	const { refreshToken } = useSelector((state: AppState) => state.auth);

	useEffect(() => {
		thunkDispatch(loadLoadingScreen(true));
		if (orderId && refreshToken) {
			getOrderById(orderId).then((res) => {
				setOrderDetails(res.body);
				thunkDispatch(loadLoadingScreen(false));
			});
		}
	}, [refreshToken]);

	const renderButton = () =>
		orderDetails?.status.toLocaleUpperCase() !== ORDER_STATUS.PAID ? (
			<PayPalButton
				options={{
					clientId: process.env.PAYPAL_CLIENT_ID,
					currency: 'EUR',
				}}
				createOrder={(data: any, actions: any) => {
					thunkDispatch(loadLoadingScreen(true));
					return actions.order.create({
						purchase_units: [
							{
								amount: {
									currency_code: `EUR`,
									value: `${orderDetails?.totalPrice}`,
								},
							},
						],
						application_context: {
							shipping_preference: 'NO_SHIPPING',
						},
					});
				}}
				onApprove={(data: any, actions: any) => {
					// Capture the funds from the transaction
					thunkDispatch(loadLoadingScreen(true));
					return actions.order.capture().then(function (details: any) {
						try {
							if (orderId) {
								confirmPayment(orderId, details.id).then((res) => {
									setIsSuccessfull(true);
								});
							}
							toast.success('Payment successful');
						} catch (err) {
							toast.error('Error during payment processing.');
						} finally {
							thunkDispatch(loadLoadingScreen(false));
						}
					});
				}}
				onError={(data: any) => {
					toast.error('Error during payment processing.');
					thunkDispatch(loadLoadingScreen(false));
				}}
				onButtonReady={() => {
					thunkDispatch(loadLoadingScreen(false));
				}}
			/>
		) : (
			<div className="success-message">
				<p>Order has been already paid for! You can now return to the orders page.</p>
				<Link to="/my-orders">&larr; Panel</Link>
			</div>
		);

	const renderMessage = () => (
		<div className="success-message">
			<p>Payment successful! You can now return to the orders page.</p>
			<Link to="/my-orders">&larr; Panel</Link>
		</div>
	);

	return (
		<LayoutRoot>
			<BrandCover filename="landing.jpg" isFull>
				{isLoading && !isBackdropActive && <Spinner isFull={true} />}
				{isSuccessfull ? renderMessage() : renderButton()}
			</BrandCover>
		</LayoutRoot>
	);
};

export default Payment;
