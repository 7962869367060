import { CoachingOrder } from 'types/order';
import { RequestMetadata, RequestResponse } from '../../apiConfig';
import { getRequest } from '../../RequestFactory';

const CREATE_COACHING_ORDER: RequestMetadata = {
	path: '/Orders/coaching',
	method: 'POST',
	headers: {
		'Content-Type': 'application/json',
	},
	shouldAuth: true,
	hasResponseBody: false,
	isDataServer: false,
	isLolApi: false,
};

type CreateCoachingOrderRequestBody = CoachingOrder;

export const createCoachingOrder = async (order: CoachingOrder): Promise<RequestResponse<undefined>> => {
	return await getRequest<CreateCoachingOrderRequestBody>(CREATE_COACHING_ORDER, {
		body: order,
	});
};

export default createCoachingOrder;
