import { PlacementOrder } from 'types/order';
import { RequestMetadata, RequestResponse } from '../../apiConfig';
import { getRequest } from '../../RequestFactory';

const CREATE_PLACEMENT_ORDER: RequestMetadata = {
	path: '/Orders/placement',
	method: 'POST',
	headers: {
		'Content-Type': 'application/json',
	},
	shouldAuth: true,
	hasResponseBody: false,
	isDataServer: false,
	isLolApi: false,
};

type CreatePlacementOrderRequestBody = PlacementOrder;

export const createPlacementOrder = async (order: PlacementOrder): Promise<RequestResponse<undefined>> => {
	return await getRequest<CreatePlacementOrderRequestBody>(CREATE_PLACEMENT_ORDER, {
		body: order,
	});
};

export default createPlacementOrder;
