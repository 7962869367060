import { PayPerGameResponseBody } from 'types/general';
import { RequestMetadata, RequestResponse } from '../../apiConfig';
import { getRequest } from '../../RequestFactory';

const GET_NET_WINS_PRICING: RequestMetadata = {
	path: '/data/pricing/boosting/netwins/data.json',
	method: 'GET',
	headers: {},
	shouldAuth: false,
	hasResponseBody: true,
	isDataServer: true,
	isLolApi: false,
};

export const getNetWinsPricing = async (): Promise<RequestResponse<PayPerGameResponseBody>> => {
	return await getRequest<undefined, PayPerGameResponseBody>({ ...GET_NET_WINS_PRICING });
};

export default getNetWinsPricing;
