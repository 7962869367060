import { navigate } from 'gatsby';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ORDER_TYPES } from '../../../constants/general';
import { loadLoadingScreen } from '../../../redux/actions/generalActions';
import { AppState, ThunkGlobalDispatch } from '../../../redux/root';
import { LeagueOrder, NetWinsOrder, PlacementOrder } from '../../types/order';
import AppearOnFocusComponent from '../../animation/AppearOnFocusComponent';
import Questions from './Questions';
import Providers from './Providers';
import FinalPrice from './FinalPrice';
import Button from 'components/shared/Button';
import Spinner from 'components/shared/Spinner';
import CouponInput from '../UI/CouponInput';
import { getCouponByCode } from '../../../api/requests/requestHandler';
import { dispatchUpdateCoupon } from '../../../redux/actions/paymentActions';

interface ISummarySectionProps {
	summaryImage: string;
}

const SummarySection: React.FC<ISummarySectionProps> = ({ summaryImage }) => {
	const {
		rankFrom,
		rankTo,
		startingLP,
		lpGain,
		queueType,
		server,
		orderType,
		totalPrice,
		isPriority,
		coupon,
		basePrice,
	} = useSelector((state: AppState) => state.payment);
	const { selectedRoles, pickedChampions, numberOfGames, serviceType, isLoading, numberOfMasterPoints } = useSelector(
		(state: AppState) => state.general,
	);
	const { username } = useSelector((state: AppState) => state.auth);
	const thunkDispatch = useDispatch<ThunkGlobalDispatch>();
	const [couponCode, setCouponName] = useState<string>('');

	const handleLeaguePurchase = () => {
		if (
			rankFrom &&
			rankTo &&
			startingLP &&
			lpGain &&
			queueType &&
			server &&
			selectedRoles !== undefined &&
			pickedChampions !== undefined &&
			totalPrice &&
			isPriority !== undefined &&
			orderType
		) {
			const startingRank = rankFrom;
			if (rankFrom.name === 'Master') startingRank.division = numberOfMasterPoints.startingPoints;

			const desiredRank = rankTo;
			if (rankTo.name === 'Master') desiredRank.division = numberOfMasterPoints.desiredPoints;

			const newOrder: LeagueOrder = {
				rankFrom: startingRank,
				rankTo: desiredRank,
				multipliers: {
					startingLP: startingLP,
					lpGain: lpGain,
					queueType: queueType,
					server: { name: server.abbreviation, multiplier: server.multiplier },
				},
				roles: selectedRoles,
				champions: pickedChampions,
				totalPrice: totalPrice,
				server: server.abbreviation,
				isPriority: isPriority,
				isDuo: orderType.toLocaleUpperCase() === ORDER_TYPES.LEAGUE ? false : true,
				currency: 'EUR',
				type: orderType,
				basePrice: basePrice,
				couponId: coupon?.id,
			};
			thunkDispatch(loadLoadingScreen(true));
			sessionStorage.setItem('cart', JSON.stringify(newOrder));
			navigate('/cart');
		}
	};

	const handleNetwinsPurchase = () => {
		if (
			rankFrom &&
			lpGain &&
			queueType &&
			server &&
			selectedRoles !== undefined &&
			pickedChampions !== undefined &&
			totalPrice &&
			isPriority !== undefined &&
			orderType &&
			serviceType
		) {
			const newOrder: NetWinsOrder = {
				rank: rankFrom,
				multipliers: {
					queueType: queueType,
					server: { name: server.abbreviation, multiplier: server.multiplier },
					lpGain: lpGain,
				},
				roles: selectedRoles,
				champions: pickedChampions,
				totalPrice: totalPrice,
				server: server?.abbreviation,
				isPriority: isPriority,
				currency: 'EUR',
				type: orderType,
				netWinsQuantity: numberOfGames,
				isDuo: serviceType === 'duo',
				basePrice: basePrice,
				couponId: coupon?.id,
			};
			thunkDispatch(loadLoadingScreen(true));
			sessionStorage.setItem('cart', JSON.stringify(newOrder));
			navigate('/cart');
		}
	};

	const handlePlacementPurchase = () => {
		if (
			rankFrom &&
			queueType &&
			server &&
			selectedRoles !== undefined &&
			pickedChampions !== undefined &&
			totalPrice &&
			isPriority !== undefined &&
			orderType &&
			serviceType
		) {
			const newOrder: PlacementOrder = {
				previousRank: rankFrom,
				multipliers: {
					queueType: queueType,
					server: { name: server.abbreviation, multiplier: server.multiplier },
				},
				roles: selectedRoles,
				champions: pickedChampions,
				totalPrice: totalPrice,
				server: server?.abbreviation,
				isPriority: isPriority,
				currency: 'EUR',
				type: orderType,
				placementWinsQuantity: numberOfGames,
				isDuo: serviceType === 'duo',
				basePrice: basePrice,
				couponId: coupon?.id,
			};
			thunkDispatch(loadLoadingScreen(true));
			sessionStorage.setItem('cart', JSON.stringify(newOrder));
			navigate('/cart');
		}
	};

	const handlePurchaseClick = () => {
		if (username === undefined) {
			return navigate(`/login`);
		}
		try {
			switch (orderType?.toLocaleUpperCase()) {
				case ORDER_TYPES.LEAGUE:
				case ORDER_TYPES.DUO: {
					return handleLeaguePurchase();
				}
				case ORDER_TYPES.NETWINS: {
					return handleNetwinsPurchase();
				}
				case ORDER_TYPES.PLACEMENT: {
					return handlePlacementPurchase();
				}
			}
		} catch (err) {
			toast.error('Error during order creation.');
		}
	};

	const renderSelectedRoles = () => {
		let text: string = '';
		if (selectedRoles && selectedRoles?.length > 1) {
			text += 'Selected roles: \n';
		}
		if (selectedRoles && selectedRoles?.length > 1) {
			selectedRoles.forEach((role, i) => {
				text += role.toLocaleUpperCase();
				text += i + 1 < selectedRoles.length ? ', ' : '\n';
			});
		}
		return text;
	};

	const renderPickedHeroes = () => {
		let text: string = '';
		if (pickedChampions && pickedChampions?.length > 1) {
			text += 'Picked champions: \n';
		}
		if (pickedChampions && pickedChampions?.length > 1) {
			pickedChampions.forEach((champion, i) => {
				text += champion.toLocaleUpperCase();
				text += i + 1 < pickedChampions.length ? ', ' : '\n \n';
			});
		}
		return text;
	};

	const renderExtrasValues = () => {
		let text: string = '';
		if (isPriority) {
			text += 'Priority order' + '\n \n';
		}
		text += renderPickedHeroes();
		text += renderSelectedRoles();
		if (text === '') {
			return 'NONE';
		}
		return text;
	};

	const handleCouponSubmit = async () => {
		if (coupon === undefined) {
			try {
				const response = await getCouponByCode(couponCode);
				if (response && response.body) {
					thunkDispatch(dispatchUpdateCoupon(response.body));
				}
				toast.success('Coupon applied');
				setCouponName('');
			} catch (err) {
				toast.error('Invalid coupon');
			}
		} else {
			toast.error('Coupon already applied');
		}
	};

	const renderSummary = () => {
		const typeOfOrder = orderType?.toLocaleUpperCase();

		if (typeOfOrder === ORDER_TYPES.LEAGUE || typeOfOrder === ORDER_TYPES.DUO) {
			return (
				<>
					<div className="summary-order">
						<AppearOnFocusComponent>
							<div className="summary-order__price">
								<FinalPrice totalPrice={totalPrice} />
								<h3>Discount for coupon?</h3>
								<CouponInput
									handleCouponSubmit={handleCouponSubmit}
									coupon={couponCode}
									setCoupon={(value) => setCouponName(value)}
								/>
								{isLoading ? (
									<Spinner />
								) : (
									<Button
										trigger={() => {
											handlePurchaseClick();
										}}
										type="button"
										variant="purchase"
									>
										PURCHASE
									</Button>
								)}
							</div>
						</AppearOnFocusComponent>
						<AppearOnFocusComponent>
							<div className="summary-order__card">
								<img src={summaryImage} />
								<h3>YOUR BOOSTING SUMMARY:</h3>
								<div className="summary-order__parameters">
									<div className="parameters">
										{coupon && <p>COUPON:</p>}
										<p>{orderType?.toUpperCase()} BOOST:</p>
										<p>REGION:</p>
										<p>QUEUE TYPE:</p>
										<p>EXTRAS:</p>
									</div>
									<div className="values">
										{coupon && (
											<p>
												{coupon.displayName} -{coupon.offPercentage}%
											</p>
										)}
										<p>
											FROM {rankFrom?.name.toLocaleUpperCase()}
											{` `}
											{rankFrom?.division}
											{` `}
											TO {rankTo?.name.toLocaleUpperCase()}
											{` `}
											{rankTo?.division}
										</p>
										<p>{server?.name.toLocaleUpperCase()}</p>
										<p>{queueType?.name === 'solo' ? 'SOLO/DUO (5v5)' : 'FLEX (5v5)'}</p>
										<p>{renderExtrasValues()}</p>
									</div>
								</div>
							</div>
						</AppearOnFocusComponent>
					</div>
					<Questions />
					<Providers />
				</>
			);
		} else if (typeOfOrder === ORDER_TYPES.NETWINS) {
			return (
				<>
					<div className="summary-order">
						<AppearOnFocusComponent>
							<div className="summary-order__price">
								<FinalPrice totalPrice={totalPrice} />
								<h3>Discount for coupon?</h3>
								<CouponInput
									handleCouponSubmit={handleCouponSubmit}
									coupon={couponCode}
									setCoupon={(value) => setCouponName(value)}
								/>
								{isLoading ? (
									<Spinner />
								) : (
									<Button
										trigger={() => {
											handlePurchaseClick();
										}}
										type="button"
										variant="purchase"
									>
										PURCHASE
									</Button>
								)}
							</div>
						</AppearOnFocusComponent>
						<AppearOnFocusComponent>
							<div className="summary-order__card">
								<img src={summaryImage} />
								<h3>YOUR BOOSTING SUMMARY:</h3>
								<div className="summary-order__parameters">
									<div className="parameters">
										{coupon && <p>COUPON:</p>}
										<p>{orderType?.toUpperCase()} BOOST:</p>
										<p>NUMBER OF GAMES:</p>
										<p>REGION:</p>
										<p>QUEUE TYPE:</p>
										<p>SERVICE TYPE:</p>
										<p>EXTRAS:</p>
									</div>
									<div className="values">
										{coupon && (
											<p>
												{coupon.displayName} -{coupon.offPercentage}%
											</p>
										)}
										<p>
											RANK: {rankFrom?.name.toLocaleUpperCase()}
											{` `}
											{rankFrom?.division}
										</p>
										<p>{numberOfGames}</p>
										<p>{server?.name.toLocaleUpperCase()}</p>
										<p>{queueType?.name === 'solo' ? 'SOLO/DUO (5v5)' : 'FLEX (5v5)'}</p>
										<p>{serviceType.toLocaleUpperCase()}</p>
										<p>{renderExtrasValues()}</p>
									</div>
								</div>
							</div>
						</AppearOnFocusComponent>
					</div>
					<Questions />
					<Providers />
				</>
			);
		} else {
			return (
				<>
					<div className="summary-order">
						<AppearOnFocusComponent>
							<div className="summary-order__price">
								<FinalPrice totalPrice={totalPrice} />
								<h3>Discount for coupon?</h3>
								<CouponInput
									handleCouponSubmit={handleCouponSubmit}
									coupon={couponCode}
									setCoupon={(value) => setCouponName(value)}
								/>
								{isLoading ? (
									<Spinner />
								) : (
									<Button
										trigger={() => {
											handlePurchaseClick();
										}}
										type="button"
										variant="purchase"
									>
										PURCHASE
									</Button>
								)}
							</div>
						</AppearOnFocusComponent>
						<AppearOnFocusComponent>
							<div className="summary-order__card">
								<img src={summaryImage} />
								<h3>YOUR BOOSTING SUMMARY:</h3>
								<div className="summary-order__parameters">
									<div className="parameters">
										{coupon && <p>COUPON:</p>}
										<p>{orderType?.toUpperCase()} BOOST:</p>
										<p>NUMBER OF GAMES:</p>
										<p>REGION:</p>
										<p>QUEUE TYPE:</p>
										<p>SERVICE TYPE:</p>
										<p>EXTRAS:</p>
									</div>
									<div className="values">
										{coupon && (
											<p>
												{coupon.displayName} -{coupon.offPercentage}%
											</p>
										)}
										<p>
											RANK: {rankFrom?.name.toLocaleUpperCase()}
											{` `}
											{rankFrom?.division}
										</p>
										<p>{numberOfGames}</p>
										<p>{server?.name.toLocaleUpperCase()}</p>
										<p>{queueType?.name === 'solo' ? 'SOLO/DUO (5v5)' : 'FLEX (5v5)'}</p>
										<p>{serviceType.toLocaleUpperCase()}</p>
										<p>{renderExtrasValues()}</p>
									</div>
								</div>
							</div>
						</AppearOnFocusComponent>
					</div>
					<Questions />
					<Providers />
				</>
			);
		}
	};

	return <div className="container">{renderSummary()}</div>;
};

export default SummarySection;
