import { RequestMetadata, RequestResponse } from '../../apiConfig';
import { getRequest } from '../../RequestFactory';
import { TiersPricing } from 'types/general';

const GET_SOLO_LEAGUE_PRICING: RequestMetadata = {
	path: '/data/pricing/boosting/league/solo/data.json',
	method: 'GET',
	headers: {},
	shouldAuth: false,
	hasResponseBody: true,
	isDataServer: true,
	isLolApi: false,
};

type GetSoloLeaguePricingResponseBody = TiersPricing;

export const getSoloLeaguePricing = async (): Promise<RequestResponse<GetSoloLeaguePricingResponseBody>> => {
	return await getRequest<undefined, GetSoloLeaguePricingResponseBody>({ ...GET_SOLO_LEAGUE_PRICING });
};

export default getSoloLeaguePricing;
