import { LoginCredentialsBody, LoginResponseBody } from 'types/auth';
import { RequestMetadata, RequestResponse } from '../../apiConfig';
import { getRequest } from '../../RequestFactory';

const AUTH_LOGIN: RequestMetadata = {
	path: '/Accounts/authenticate',
	headers: { 'Content-Type': 'application/json' },
	method: 'POST',
	shouldAuth: false,
	hasResponseBody: true,
	isDataServer: false,
	isLolApi: false,
	credentials: true,
};

export const authLogin = async (username: string, password: string): Promise<RequestResponse<LoginResponseBody>> => {
	return await getRequest<LoginCredentialsBody, LoginResponseBody>(AUTH_LOGIN, {
		body: {
			email: username,
			password: password,
		},
	});
};

export default authLogin;
