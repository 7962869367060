import { User } from 'types/auth';
import { RequestMetadata, RequestResponse } from '../../apiConfig';
import { getRequest } from '../../RequestFactory';

const GET_ACCOUNT_BY_ID: RequestMetadata = {
	path: '/Accounts/{id}',
	method: 'GET',
	headers: {},
	shouldAuth: true,
	hasResponseBody: true,
	isDataServer: false,
	isLolApi: false,
};

type GetAccountByIdResponseBody = User;

export const getAccountById = async (userId: string): Promise<RequestResponse<GetAccountByIdResponseBody>> => {
	return await getRequest<undefined, GetAccountByIdResponseBody>(GET_ACCOUNT_BY_ID, {
		path: new Map<string, string>([['id', `${userId}`]]),
	});
};

export default getAccountById;
