import { CoachingPricingResponseBody } from 'types/general';
import { RequestMetadata, RequestResponse } from '../../apiConfig';
import { getRequest } from '../../RequestFactory';

const GET_COACHING_PRICING: RequestMetadata = {
	path: '/data/pricing/coaching/data.json',
	method: 'GET',
	headers: {},
	shouldAuth: false,
	hasResponseBody: true,
	isDataServer: true,
	isLolApi: false,
};

export const getCoachingPricing = async (): Promise<RequestResponse<CoachingPricingResponseBody>> => {
	return await getRequest<undefined, CoachingPricingResponseBody>({ ...GET_COACHING_PRICING });
};

export default getCoachingPricing;
