import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkGlobalDispatch, AppState } from '../../../redux/root';
import {
	dispatchWithOrderPriceCalculation,
	setExtras,
	setLPGain,
	setOrderType,
	setQueueType,
	setRankFrom,
	setRankTo,
	setServer,
	setStartingLP,
	setTiersPricing,
} from '../../../redux/actions/paymentActions';
import { TiersPricing, OrderRank, RequestResponseMultiplier } from '../../types/general';
import League from './League';
import ServicesDropdown from '../UI/ServicesDivisionDropdown';
import {
	getExtrasPricing,
	getLpGainPricing,
	getQueueTypePricing,
	getServerPricing,
	getSoloLeaguePricing,
	getStartingLpPricing,
} from '../../../api/requests/requestHandler';
import NumericalInput from '../UI/NumericalInput';
import {
	updateNumberOfMasterDesiredPoints,
	updateNumberOfMasterStartingPoints,
} from '../../../redux/actions/generalActions';
import ParamsExtras from '../OrderParams/ParamsExtras';
import ParamsStartingLp from '../OrderParams/ParamsStartingLp';
import ParamsLpGain from '../OrderParams/ParamsLpGain';
import ParamsQueueType from '../OrderParams/ParamsQueueType';
import ParamsServer from '../OrderParams/ParamsServer';

const LeagueBoosting: React.FC = () => {
	const thunkDispatch = useDispatch<ThunkGlobalDispatch>();
	const { rankFrom, rankTo, startingLP, lpGain, queueType, server, tiersPricing, orderType, totalPrice } = useSelector(
		(state: AppState) => state.payment,
	);
	const { numberOfMasterPoints } = useSelector((state: AppState) => state.general);
	const [startingLpPricing, setStartingLpPricing] = useState<RequestResponseMultiplier>();
	const [lpGainPricing, setLpGainPricing] = useState<RequestResponseMultiplier>();
	const [queueTypePricing, setQueueTypePricing] = useState<RequestResponseMultiplier>();
	const [serverPricing, setServerPricing] = useState<RequestResponseMultiplier>();

	const FIRST_ELEMENT = 0;

	const fetchPricingData = async () => {
		let fetchedTiers: TiersPricing | undefined;
		await Promise.resolve(
			getSoloLeaguePricing().then((res) => {
				fetchedTiers = res.body;
				thunkDispatch(setTiersPricing(res.body));
			}),
		);
		thunkDispatch(dispatchWithOrderPriceCalculation(setRankFrom({ name: 'Silver', division: 1 }), fetchedTiers));
		thunkDispatch(dispatchWithOrderPriceCalculation(setRankTo({ name: 'Gold', division: 4 }), fetchedTiers));
		getStartingLpPricing().then((res) => {
			setStartingLpPricing(res.body?.startingLP);
			thunkDispatch(
				dispatchWithOrderPriceCalculation(setStartingLP(res.body?.startingLP[FIRST_ELEMENT]), fetchedTiers),
			);
		});
		getLpGainPricing().then((res) => {
			setLpGainPricing(res.body?.lpGain);
			thunkDispatch(dispatchWithOrderPriceCalculation(setLPGain(res.body?.lpGain[FIRST_ELEMENT]), fetchedTiers));
		});
		getQueueTypePricing().then((res) => {
			setQueueTypePricing(res.body?.queueType);
			thunkDispatch(dispatchWithOrderPriceCalculation(setQueueType(res.body?.queueType[FIRST_ELEMENT]), fetchedTiers));
		});
		getServerPricing().then((res) => {
			setServerPricing(res.body?.server);
			thunkDispatch(dispatchWithOrderPriceCalculation(setServer(res.body?.server[FIRST_ELEMENT]), fetchedTiers));
		});
		getExtrasPricing().then((res) => {
			thunkDispatch(dispatchWithOrderPriceCalculation(setExtras(res.body), fetchedTiers));
		});
	};

	useEffect(() => {
		if (orderType !== 'league' || !totalPrice) {
			thunkDispatch(setOrderType('league'));
			fetchPricingData();
		}
	}, []);

	const checkShouldUpdateLeagues = (rankFrom: OrderRank, rankTo: OrderRank) => {
		if (rankFrom.division !== null && rankTo.division !== null) {
			if (rankFrom.name === rankTo.name) {
				if (rankFrom.division === rankTo.division) {
					return true;
				} else {
					return rankTo.division > rankFrom.division;
				}
			} else {
				const startingTier = tiersPricing?.tiers.find((t) => t.name === rankFrom.name);
				const desiredTier = tiersPricing?.tiers.find((t) => t.name === rankTo.name);
				if (startingTier && desiredTier) {
					const rankFromIndex = tiersPricing?.tiers.indexOf(startingTier);
					const rankToIndex = tiersPricing?.tiers.indexOf(desiredTier);
					if (rankFromIndex !== undefined && rankToIndex !== undefined) {
						return rankToIndex < rankFromIndex;
					}
				}
			}
		} else if (rankFrom.division === null && rankTo.division !== null) {
			return true;
		} else {
			return false;
		}
	};

	const incrementLeague = (rankFrom: OrderRank) => {
		if (rankTo) {
			if (rankFrom.division !== null) {
				if (rankFrom.division !== 1) {
					thunkDispatch(dispatchWithOrderPriceCalculation(setRankFrom(rankFrom), tiersPricing));
					thunkDispatch(
						dispatchWithOrderPriceCalculation(
							setRankTo({ name: rankFrom.name, division: rankFrom.division - 1 }),
							tiersPricing,
						),
					);
				} else {
					if (rankFrom.name !== 'Diamond') {
						thunkDispatch(dispatchWithOrderPriceCalculation(setRankFrom(rankFrom), tiersPricing));
						const rankToIndex = tiersPricing?.tiers.findIndex((t) => t.name === rankTo.name);
						const nextRankName = rankToIndex !== undefined ? tiersPricing?.tiers[rankToIndex + 1].name : undefined;
						if (nextRankName) {
							thunkDispatch(
								dispatchWithOrderPriceCalculation(setRankTo({ name: nextRankName, division: 4 }), tiersPricing),
							);
						}
					} else {
						thunkDispatch(dispatchWithOrderPriceCalculation(setRankFrom(rankFrom), tiersPricing));
						thunkDispatch(
							dispatchWithOrderPriceCalculation(setRankTo({ name: 'Master', division: null }), tiersPricing),
						);
					}
				}
			} else {
				thunkDispatch(dispatchWithOrderPriceCalculation(setRankFrom(rankFrom), tiersPricing));
				thunkDispatch(
					dispatchWithOrderPriceCalculation(setRankTo({ name: rankFrom.name, division: null }), tiersPricing),
				);
			}
		}
	};

	const decrementLeague = (rankTo: OrderRank) => {
		if (rankFrom) {
			if (rankFrom.division !== null) {
				if (rankTo.name === 'Iron') {
					thunkDispatch(
						dispatchWithOrderPriceCalculation(setRankFrom({ name: rankTo.name, division: 4 }), tiersPricing),
					);
					thunkDispatch(dispatchWithOrderPriceCalculation(setRankTo({ name: rankTo.name, division: 3 }), tiersPricing));
					return;
				}
				thunkDispatch(setRankTo(rankTo));
				const rankToIndex = tiersPricing?.tiers.findIndex((t) => t.name === rankTo.name);
				const previousRankName = rankToIndex !== undefined ? tiersPricing?.tiers[rankToIndex - 1].name : undefined;
				if (rankToIndex !== 0 && previousRankName !== undefined) {
					thunkDispatch(
						dispatchWithOrderPriceCalculation(setRankFrom({ name: previousRankName, division: 1 }), tiersPricing),
					);
				}
			} else {
				thunkDispatch(dispatchWithOrderPriceCalculation(setRankFrom({ name: rankTo.name, division: 4 }), tiersPricing));
				thunkDispatch(dispatchWithOrderPriceCalculation(setRankTo({ name: rankTo.name, division: 3 }), tiersPricing));
			}
		}
	};

	const handleLeagueSelection = (rank: OrderRank, type: string) => {
		if (rankTo !== undefined && rankFrom !== undefined) {
			switch (type) {
				case 'starting': {
					if (checkShouldUpdateLeagues(rank, rankTo)) {
						incrementLeague(rank);
					} else {
						thunkDispatch(dispatchWithOrderPriceCalculation(setRankFrom(rank), tiersPricing));
					}

					break;
				}
				case 'desired': {
					if (checkShouldUpdateLeagues(rankFrom, rank)) {
						decrementLeague(rank);
					} else {
						thunkDispatch(dispatchWithOrderPriceCalculation(setRankTo(rank), tiersPricing));
					}

					break;
				}
			}
		}
	};

	const renderStartingLeagues = () => {
		if (tiersPricing) {
			const avatarIndex = tiersPricing.tiers
				.find((tier) => tier.name === rankFrom?.name)
				?.divisions.findIndex((div) => div.division === rankFrom?.division);
			return tiersPricing.tiers.map((tier, index) => {
				if (tier.name === rankFrom?.name && avatarIndex !== undefined) {
					if (tier.name === 'Master') {
						return (
							<League
								key={tier.name + index}
								handleLeagueSelection={() => handleLeagueSelection({ name: tier.name, division: null }, 'starting')}
								name={tier.name}
								imgSrc={`https://data.bamboosting.net/${tier.divisions[0].avatar}`}
								isActive
							/>
						);
					}
					return (
						<League
							key={`${tier.name}` + `${avatarIndex}`}
							handleLeagueSelection={() => handleLeagueSelection({ name: tier.name, division: 4 }, 'starting')}
							name={tier.name}
							imgSrc={`https://data.bamboosting.net/${tier.divisions[avatarIndex].avatar}`}
							isActive
						/>
					);
				}
				if (tier.name === 'Master') {
					return (
						<League
							key={tier.name + index}
							handleLeagueSelection={() => handleLeagueSelection({ name: tier.name, division: null }, 'starting')}
							name={tier.name}
							imgSrc={`https://data.bamboosting.net/${tier.divisions[0].avatar}`}
						/>
					);
				}
				return (
					<League
						key={tier.name + index}
						handleLeagueSelection={() => handleLeagueSelection({ name: tier.name, division: 4 }, 'starting')}
						name={tier.name}
						imgSrc={`https://data.bamboosting.net/${tier.divisions[tier.divisions.length - 1].avatar}`}
					/>
				);
			});
		}
	};

	const renderDesiredLeagues = () => {
		if (tiersPricing) {
			const avatarIndex = tiersPricing.tiers
				.find((tier) => tier.name === rankTo?.name)
				?.divisions.findIndex((div) => div.division === rankTo?.division);
			return tiersPricing.tiers.map((tier, index) => {
				if (tier.name === rankTo?.name && avatarIndex !== undefined) {
					if (tier.name === 'Master') {
						return (
							<League
								key={tier.name + index}
								handleLeagueSelection={() => handleLeagueSelection({ name: tier.name, division: null }, 'desired')}
								name={tier.name}
								imgSrc={`https://data.bamboosting.net/${tier.divisions[0].avatar}`}
								isActive
							/>
						);
					}
					return (
						<League
							key={`${tier.name}` + `${avatarIndex}`}
							handleLeagueSelection={() => handleLeagueSelection({ name: tier.name, division: 4 }, 'desired')}
							name={tier.name}
							imgSrc={`https://data.bamboosting.net/${tier.divisions[avatarIndex].avatar}`}
							isActive
						/>
					);
				}
				if (tier.name === 'Master') {
					return (
						<League
							key={tier.name + index}
							handleLeagueSelection={() => handleLeagueSelection({ name: tier.name, division: null }, 'desired')}
							name={tier.name}
							imgSrc={`https://data.bamboosting.net/${tier.divisions[0].avatar}`}
						/>
					);
				}
				return (
					<League
						key={tier.name + index}
						handleLeagueSelection={() => handleLeagueSelection({ name: tier.name, division: 4 }, 'desired')}
						name={tier.name}
						imgSrc={`https://data.bamboosting.net/${tier.divisions[tier.divisions.length - 1].avatar}`}
					/>
				);
			});
		}
	};

	const createOptionsForDropdown = (type: string): OrderRank[] => {
		const ranksToRender: OrderRank[] = [];
		if (tiersPricing && type === 'starting') {
			tiersPricing.tiers
				.find((tier) => tier.name === rankFrom?.name)
				?.divisions.forEach((div) => ranksToRender.push({ name: div.name, division: div.division }));
		} else if (tiersPricing && type === 'desired') {
			tiersPricing.tiers
				.find((tier) => tier.name === rankTo?.name)
				?.divisions.forEach((div) => ranksToRender.push({ name: div.name, division: div.division }));
		}
		return ranksToRender;
	};

	const handleNumberOfMasterPointsUpdate = (type: string, pointsType: string) => {
		const masterPoints = numberOfMasterPoints;
		if (pointsType === 'starting') {
			if (type === 'up' && masterPoints.startingPoints + 1 < masterPoints.desiredPoints) {
				thunkDispatch(
					dispatchWithOrderPriceCalculation(
						updateNumberOfMasterStartingPoints(masterPoints.startingPoints + 1),
						tiersPricing,
					),
				);
			} else if (type === 'down' && masterPoints.startingPoints > 0) {
				thunkDispatch(
					dispatchWithOrderPriceCalculation(
						updateNumberOfMasterStartingPoints(masterPoints.startingPoints - 1),
						tiersPricing,
					),
				);
			}
		} else {
			if (type === 'up') {
				thunkDispatch(
					dispatchWithOrderPriceCalculation(
						updateNumberOfMasterDesiredPoints(masterPoints.desiredPoints + 1),
						tiersPricing,
					),
				);
			} else if (
				type === 'down' &&
				masterPoints.desiredPoints > 0 &&
				masterPoints.desiredPoints - 1 > masterPoints.startingPoints
			) {
				thunkDispatch(
					dispatchWithOrderPriceCalculation(
						updateNumberOfMasterDesiredPoints(masterPoints.desiredPoints - 1),
						tiersPricing,
					),
				);
			}
		}
	};

	const handleNumberOfMasterStartingPointsChange = (value: number) => {
		thunkDispatch(dispatchWithOrderPriceCalculation(updateNumberOfMasterStartingPoints(value), tiersPricing));
	};

	const handleNumberOfMasterDesiredPointsChange = (value: number) => {
		thunkDispatch(dispatchWithOrderPriceCalculation(updateNumberOfMasterDesiredPoints(value), tiersPricing));
	};

	const renderDivisionSelectionSection = () => (
		<>
			<div className="params-header">
				<span>01</span>
				<h2>Your current league:</h2>
			</div>
			<div className="leagues-list">{renderStartingLeagues()}</div>
			{rankFrom?.name !== 'Master' ? (
				<ServicesDropdown
					handleDivisionChange={(rank: OrderRank) => handleLeagueSelection(rank, 'starting')}
					type="starting"
					options={createOptionsForDropdown('starting')}
				/>
			) : (
				<NumericalInput
					handleIncrementAction={() => handleNumberOfMasterPointsUpdate('up', 'starting')}
					handleDecrementAction={() => handleNumberOfMasterPointsUpdate('down', 'starting')}
					value={numberOfMasterPoints.startingPoints}
					numericalActionToDispatch={(value) => handleNumberOfMasterStartingPointsChange(value)}
					variant="blue"
				/>
			)}
			<div className="params-header">
				<span>02</span>
				<h2>Your desired league:</h2>
			</div>
			<div className="leagues-list leagues-list--desired">{renderDesiredLeagues()}</div>
			{rankTo?.name !== 'Master' ? (
				<ServicesDropdown
					handleDivisionChange={(rank: OrderRank) => handleLeagueSelection(rank, 'desired')}
					type="desired"
					options={createOptionsForDropdown('desired')}
				/>
			) : (
				<NumericalInput
					handleIncrementAction={() => handleNumberOfMasterPointsUpdate('up', 'desired')}
					handleDecrementAction={() => handleNumberOfMasterPointsUpdate('down', 'desired')}
					value={numberOfMasterPoints.desiredPoints}
					numericalActionToDispatch={(value) => handleNumberOfMasterDesiredPointsChange(value)}
				/>
			)}
		</>
	);

	return (
		<>
			<section className="order-creation">
				<div className="boosting-params container">
					<div className="boosting-params__leagues">{renderDivisionSelectionSection()}</div>
					<div className="boosting-params__dropdowns">
						<ParamsStartingLp tiersPricing={tiersPricing} value={startingLP?.range} paramPricing={startingLpPricing} />
						<ParamsLpGain tiersPricing={tiersPricing} value={lpGain?.range} paramPricing={lpGainPricing} />
						<ParamsQueueType tiersPricing={tiersPricing} value={queueType?.name} paramPricing={queueTypePricing} />
						<ParamsServer tiersPricing={tiersPricing} value={server?.name} paramPricing={serverPricing} />
					</div>
					<ParamsExtras />
				</div>
			</section>
		</>
	);
};

export default LeagueBoosting;
