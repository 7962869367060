import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkGlobalDispatch, AppState } from '../../redux/root';
import Button from 'components/shared/Button';
import BrandCover from 'components/shared/BrandCover';
import Spinner from 'components/shared/Spinner';
import { useForm } from 'react-hook-form';
import { authRegister } from '../../api/requests/requestHandler';
import { loadLoadingScreen } from '../../redux/actions/generalActions';
import { CountryData } from 'types/general';
import { toast } from 'react-toastify';
import { RouteComponentProps } from '@reach/router';
import LayoutRoot from 'components/shared/LayoutRoot';

interface IRegisterProps extends RouteComponentProps {}

type FormValues = {
	email: string;
	username: string;
	password: string;
	discord: string;
	country: string;
	retype_password: string;
	acceptTerms: boolean;
	skype: string;
};

const RegistrationPage: React.FC<IRegisterProps> = () => {
	const thunkDispatch = useDispatch<ThunkGlobalDispatch>();
	const { isLoading } = useSelector((state: AppState) => state.general);
	const [countries, setCountries] = useState<CountryData[]>();
	const password = useRef();
	const { register, handleSubmit, errors, formState, watch } = useForm({ mode: 'onChange' });
	password.current = watch('password', '');
	const [message, setMessage] = useState<string>();
	const [showPassword, setShowPassword] = useState<boolean>(false);

	const onSubmit = async (data: FormValues) => {
		thunkDispatch(loadLoadingScreen(true));
		try {
			const response = await authRegister(
				data.email,
				data.username,
				data.password,
				data.discord,
				data.country,
				data.retype_password,
				data.acceptTerms,
				data.skype,
			);
			if (response.body) {
				setMessage(response.body.message);
			}
		} catch (err) {
			toast.error('Error during account creation.');
		} finally {
			thunkDispatch(loadLoadingScreen(false));
		}
	};

	useEffect(() => {
		const fetchCountries = async () => {
			const response = await fetch('https://restcountries.eu/rest/v2/all');
			const data = await response.json();
			setCountries(data);
		};
		fetchCountries();
	}, []);

	const renderCountriesData = () => {
		const countryList: JSX.Element[] = [
			<option key="none" value="none">
				Not selected
			</option>,
		];

		countries?.map((country) => {
			countryList.push(
				<option key={country.alpha2Code} value={country.alpha2Code}>
					{country.name}
				</option>,
			);
		});

		return countryList;
	};

	const renderFormBody = () => {
		return (
			<>
				<div className="login-form__wrapper">
					<div className="login-form__column">
						<label>
							E-MAIL
							{errors.email && <span> {errors.email.message}</span>}
						</label>
						<input
							name="email"
							ref={register({
								required: 'Please specify email.',
								pattern: {
									value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
									message: 'Invalid email.',
								},
							})}
							required
							type="email"
							className="login-input"
							placeholder="E-mail"
						/>
						<label>
							USERNAME
							{errors.username && <span> {errors.username.message}</span>}
						</label>
						<input
							name="username"
							ref={register({
								required: 'Please specify username.',
							})}
							required
							type="text"
							className="login-input"
							placeholder="Username"
						/>
						<label>PASSWORD {errors.password && <span>{errors.password.message}</span>}</label>
						<div className="pass-wrapper">
							<input
								name="password"
								ref={register({
									required: 'Please specify a password.',
									minLength: {
										value: 8,
										message: 'Password must have at least 8 characters',
									},
								})}
								required
								type={showPassword ? 'text' : 'password'}
								className="login-input"
								placeholder="Password"
							/>
							<span onClick={() => setShowPassword(!showPassword)} className="material-icons visibility-changer">
								{showPassword ? 'visibility_off' : 'visibility'}
							</span>
						</div>

						<label>RETYPE PASSWORD {errors.retype_password && <span>{errors.retype_password.message}</span>}</label>
						<div className="pass-wrapper">
							<input
								name="retype_password"
								required
								ref={register({
									required: true,
									validate: (value) => value === password.current || "Passwords don't match.",
								})}
								type={showPassword ? 'text' : 'password'}
								className="login-input"
								placeholder="Retype Password"
							/>
							<span onClick={() => setShowPassword(!showPassword)} className="material-icons visibility-changer">
								{showPassword ? 'visibility_off' : 'visibility'}
							</span>
						</div>
					</div>
					<div className="login-form__column">
						<label>COUNTRY</label>
						<select
							name="country"
							ref={register({ required: true, validate: (value) => value !== 'none' })}
							required
							className="login-input"
						>
							{renderCountriesData()}
						</select>
						<label>
							DISCORD (OPTIONAL)
							{errors.discord && <span> {errors.discord.message}</span>}
						</label>
						<input
							name="discord"
							ref={register({
								required: false,
								pattern: {
									value: /\w+#\d{4}/i,
									message: 'Invalid Discord username.',
								},
							})}
							type="text"
							className="login-input"
							placeholder="Discord username"
						/>
						<label>SKYPE (OPTIONAL)</label>
						<input
							name="skype"
							ref={register({ required: false })}
							type="text"
							className="login-input"
							placeholder="Skype"
						/>
						<label className="input-container">
							Accept Terms of Services
							<input name="acceptTerms" required ref={register({ required: true })} type="checkbox" />
							<span className="checkmark"></span>
						</label>
					</div>
				</div>
				<Button isDisabled={!formState.isValid} type="submit" variant="sign-in">
					Sign Up
				</Button>
			</>
		);
	};

	return (
		<LayoutRoot>
			<BrandCover className="wide" filename="landing.jpg" isFull>
				<form className="register-form" onSubmit={handleSubmit(onSubmit)}>
					{!message && (isLoading ? <Spinner /> : renderFormBody())}
					{message && <h2>{message}</h2>}
				</form>
			</BrandCover>
		</LayoutRoot>
	);
};

export default RegistrationPage;
