import {
	ForgotPasswordRequestBody,
	ForgotPasswordResponseBody,
	ResetPasswordRequestBody,
	ResetPasswordResponseBody,
} from 'types/auth';
import { RequestMetadata, RequestResponse } from '../../apiConfig';
import { getRequest } from '../../RequestFactory';

const AUTH_RESET_PASSWORD: RequestMetadata = {
	path: '/Accounts/reset-password',
	headers: { 'Content-Type': 'application/json' },
	method: 'POST',
	shouldAuth: false,
	hasResponseBody: true,
	isDataServer: false,
	isLolApi: false,
	credentials: true,
};

export const authResetPassword = async (
	password: string,
	confirmPassword: string,
	token: string,
): Promise<RequestResponse<ResetPasswordResponseBody>> => {
	return await getRequest<ResetPasswordRequestBody, ResetPasswordResponseBody>(AUTH_RESET_PASSWORD, {
		body: {
			password,
			confirmPassword,
			token,
		},
	});
};

export default authResetPassword;
