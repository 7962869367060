import { UpdateUserRequestBody, User } from 'types/auth';
import { RequestMetadata, RequestResponse } from '../../apiConfig';
import { getRequest } from '../../RequestFactory';

const UPDATE_ACCOUNT_BY_ID: RequestMetadata = {
	path: '/Accounts/{id}',
	method: 'PUT',
	headers: { 'Content-Type': 'application/json' },
	shouldAuth: true,
	hasResponseBody: true,
	isDataServer: false,
	isLolApi: false,
};

export const getAccountById = async (
	userId: string,
	userDetails: UpdateUserRequestBody,
): Promise<RequestResponse<undefined>> => {
	return await getRequest<UpdateUserRequestBody, undefined>(UPDATE_ACCOUNT_BY_ID, {
		path: new Map<string, string>([['id', `${userId}`]]),
		body: { ...userDetails },
	});
};

export default getAccountById;
