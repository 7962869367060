import React, { useEffect, useState } from 'react';
import { getChampionsList } from '../../../api/requests/requestHandler';
import { Champion, PayPerGamePricing, TiersPricing } from '../../types/general';
import ChampionTile from './ChampionTile';
import { Scrollbars } from 'react-custom-scrollbars';
import Backdrop from 'components/shared/Backdrop';
import Button from 'components/shared/Button';
import { useDispatch, useSelector } from 'react-redux';
import { AppState, ThunkGlobalDispatch } from '../../../redux/root';
import { loadBackdrop, updatePickedChampions } from '../../../redux/actions/generalActions';
import { dispatchWithOrderPriceCalculation } from '../../../redux/actions/paymentActions';
import { useOutsideAlerter } from '../../../hooks/useOutsideAlerter';
import { ORDER_TYPES } from '../../../constants/general';

const ChampionPicker: React.FC = () => {
	const [champions, setChampions] = useState<Champion[]>();
	const [baseChampions, setBaseChampions] = useState<Champion[]>();
	const thunkDispatch = useDispatch<ThunkGlobalDispatch>();
	const [championNames, setChampionNames] = useState<string[]>([]);
	const { pickedChampions, isBackdropActive } = useSelector((state: AppState) => state.general);
	const refInput = React.createRef<HTMLInputElement>();
	const pickerRef = React.createRef<HTMLDivElement>();
	const { orderType, tiersPricing, netWinsPricing, placementPricing } = useSelector((state: AppState) => state.payment);

	const handleBackdropClose = () => {
		if (isBackdropActive && championNames.length > 0) {
			thunkDispatch(dispatchWithOrderPriceCalculation(updatePickedChampions([]), pricing));
			setChampionNames([]);
		}
	};

	useOutsideAlerter(pickerRef as React.MutableRefObject<HTMLDivElement>, () => handleBackdropClose());

	const assignPricingSource = () => {
		switch (orderType?.toLocaleUpperCase()) {
			case ORDER_TYPES.LEAGUE:
			case ORDER_TYPES.DUO: {
				if (tiersPricing) {
					return tiersPricing;
				}
			}
			case ORDER_TYPES.NETWINS: {
				if (netWinsPricing) {
					return netWinsPricing;
				}
			}
			case ORDER_TYPES.PLACEMENT: {
				if (placementPricing) {
					return placementPricing;
				}
			}
		}
	};
	const pricing: TiersPricing | PayPerGamePricing | undefined = assignPricingSource();

	useEffect(() => {
		getChampionsList().then((res) => {
			setBaseChampions(res.body);
			setChampions(res.body);
		});
	}, []);

	const handleChampionTileClick = (name: string) => {
		const namesToUpdate = [...championNames];
		if (namesToUpdate.includes(name)) {
			namesToUpdate.splice(namesToUpdate.indexOf(name), 1);
		} else {
			namesToUpdate.push(name);
		}
		setChampionNames(namesToUpdate);
	};

	const renderChampionsList = () => {
		if (champions) {
			return champions.map((champ) => {
				if (champ.id !== -1) {
					return (
						<ChampionTile
							handleChampionTileClick={() => handleChampionTileClick(champ.name)}
							isActive={championNames?.includes(champ.name)}
							key={champ.id}
							championData={champ}
						/>
					);
				}
			});
		}
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (baseChampions) {
			const championsToFilter = [...baseChampions];
			const champs = championsToFilter.filter((champ) =>
				champ.name.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()),
			);
			setChampions(champs);
		}
	};

	return (
		<Backdrop isMain={false}>
			<div
				ref={pickerRef}
				onMouseDown={(e) => {
					e.stopPropagation();
				}}
				className="champion-tiles-list"
			>
				<Button
					trigger={() => {
						thunkDispatch(loadBackdrop(false));
						if (pickedChampions === undefined) {
							setChampionNames([]);
						} else if (pickedChampions !== undefined) {
							setChampionNames(pickedChampions);
						}
						document.body.style.overflow = 'auto';
					}}
					type="button"
					variant="cancel"
				>
					&times;
				</Button>
				<div className="champion-tiles-list__header">
					<h2>Pick specific champions (min. 3):</h2>
					<label
						onClick={() => {
							if (refInput.current !== null) {
								refInput.current.value = '';
								setChampions(baseChampions);
							}
						}}
					>
						&times;
					</label>
					<input ref={refInput} placeholder="Search" onChange={(e) => handleInputChange(e)} type="text" />
				</div>
				<Scrollbars className={'champion-tiles-list__scrollbar-wrapper'}>{renderChampionsList()}</Scrollbars>
				<div className="champion-tiles-list__footer">
					<div className="search-results">
						<p>
							Search results: {champions?.length} / {baseChampions?.length} total
						</p>
					</div>
					<p>
						Selected: <span>{championNames.length}</span>
					</p>
					<div className="modal-buttons">
						{pickedChampions !== undefined && pickedChampions?.length > 1 && (
							<Button
								trigger={() => {
									thunkDispatch(dispatchWithOrderPriceCalculation(updatePickedChampions([]), pricing));
									setChampionNames([]);
								}}
								type="button"
								variant="extras"
							>
								Discard selection
							</Button>
						)}
						<Button
							trigger={() => {
								thunkDispatch(loadBackdrop(false));
								document.body.style.overflow = 'auto';
								thunkDispatch(dispatchWithOrderPriceCalculation(updatePickedChampions(championNames), pricing));
							}}
							isDisabled={championNames.length < 3}
							type="button"
							variant="purchase"
						>
							Accept
						</Button>
					</div>
				</div>
			</div>
		</Backdrop>
	);
};

export default ChampionPicker;
