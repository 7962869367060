import { RequestMetadata, RequestResponse } from '../../apiConfig';
import { getRequest } from '../../RequestFactory';
import { SubmitFormRequestBody } from 'types/order';

const SUBMIT_FORM: RequestMetadata = {
	path: '/contact-form/send',
	method: 'POST',
	headers: { 'Content-Type': 'application/json' },
	shouldAuth: true,
	hasResponseBody: false,
	isDataServer: false,
	isLolApi: false,
};

export const confirmPayment = async (
	providedEmail: string,
	subject: string,
	message: string,
): Promise<RequestResponse<undefined>> => {
	return await getRequest<SubmitFormRequestBody, undefined>(SUBMIT_FORM, {
		body: {
			providedEmail,
			subject,
			message,
		},
	});
};

export default confirmPayment;
