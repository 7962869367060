import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ORDER_TYPES } from '../../../constants/general';
import { updateSelectedRoles } from '../../../redux/actions/generalActions';
import { dispatchWithOrderPriceCalculation } from '../../../redux/actions/paymentActions';
import { AppState, ThunkGlobalDispatch } from '../../../redux/root';
import { TiersPricing, PayPerGamePricing } from '../../types/general';
import clsx from 'clsx';
interface IRoleProps {
	name: string;
	imgUrl?: string;
}

const Role: React.FC<IRoleProps> = ({ name, imgUrl }) => {
	const { selectedRoles } = useSelector((state: AppState) => state.general);
	const thunkDispatch = useDispatch<ThunkGlobalDispatch>();
	const isSelected = selectedRoles?.includes(name.toLowerCase());
	const { tiersPricing, netWinsPricing, orderType, placementPricing } = useSelector((state: AppState) => state.payment);

	const assignPricingSource = () => {
		switch (orderType?.toLocaleUpperCase()) {
			case ORDER_TYPES.LEAGUE:
			case ORDER_TYPES.DUO: {
				if (tiersPricing) {
					return tiersPricing;
				}
			}
			case ORDER_TYPES.NETWINS: {
				if (netWinsPricing) {
					return netWinsPricing;
				}
			}
			case ORDER_TYPES.PLACEMENT: {
				if (placementPricing) {
					return placementPricing;
				}
			}
		}
	};
	const pricing: TiersPricing | PayPerGamePricing | undefined = assignPricingSource();

	const handleRoleClick = () => {
		const roleName = name.toLowerCase();
		if (selectedRoles !== undefined) {
			const rolesToUpdate = [...selectedRoles];
			if (rolesToUpdate.includes(roleName)) {
				rolesToUpdate.splice(
					rolesToUpdate.findIndex((role) => role === roleName),
					1,
				);
			} else {
				rolesToUpdate.push(roleName);
			}
			thunkDispatch(dispatchWithOrderPriceCalculation(updateSelectedRoles(rolesToUpdate), pricing));
		}
	};

	return (
		<div onClick={() => handleRoleClick()} className={clsx({ selected: isSelected }, 'role')}>
			<img src={imgUrl} className="role__image" />
			<h2 className="role__name">{name}</h2>
		</div>
	);
};

export default Role;
