import React from 'react';
import { Booster } from 'types/general';
import { DATA_SERVER_URL } from '../../../api/apiConfig';
import { COACH_RANK } from '../../../constants/general';
const diamond = require('../../../images/icons/diamond.webp');
const master = require('../../../images/icons/master.webp');
const grandmaster = require('../../../images/icons/grandmaster.webp');
const challenger = require('../../../images/icons/challenger.webp');

interface IBoosterTileProps {
	booster: Booster;
}

const BoosterTile: React.FC<IBoosterTileProps> = ({ booster }) => {
	const renderCountryFlags = () => {
		return booster.countryCodes.map((code) => {
			return <img key={code} src={`https://flagcdn.com/h60/${code.toLowerCase()}.png`} />;
		});
	};

	const renderRankIcon = () => {
		switch (booster.league.name) {
			case COACH_RANK.Master: {
				return <img className="booster-tile__rank" src={master} />;
			}
			case COACH_RANK.Grandmaster: {
				return <img className="booster-tile__rank" src={grandmaster} />;
			}
			case COACH_RANK.Challenger: {
				return <img className="booster-tile__rank" src={challenger} />;
			}
			default: {
				return <img className="booster-tile__rank" src={diamond} />;
			}
		}
	};

	const renderRoles = () => {
		let boosterRoles: string = '';
		booster.roles.map((role, index) => {
			boosterRoles += role.name;
			index !== booster.roles.length - 1 ? (boosterRoles += ' | ') : null;
		});
		return boosterRoles;
	};

	return (
		<div className="booster-tile">
			<div className="booster-tile__img">
				<img src={`${DATA_SERVER_URL}/data/boosters/${booster.avatar}`} />
			</div>
			<h2>{booster.nick}</h2>
			<div className="booster-tile__flags">{renderCountryFlags()}</div>
			<p>
				ROLES: <span>{renderRoles()}</span>
			</p>
			{renderRankIcon()}
		</div>
	);
};

export default BoosterTile;
