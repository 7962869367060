import React from 'react';
import { useDispatch } from 'react-redux';
import { dispatchWithOrderPriceCalculation, setServer } from '../../../redux/actions/paymentActions';
import { ThunkGlobalDispatch } from '../../../redux/root';
import { BaseNameMultiplier, OrderParamsProps } from '../../types/general';
import ServicesMultiplierDropdown from '../UI/ServicesMultiplierDropdown';

interface ParamsServerProps extends OrderParamsProps {}

const ParamsServer: React.FC<ParamsServerProps> = ({ value, tiersPricing, paramPricing }) => {
	const thunkDispatch = useDispatch<ThunkGlobalDispatch>();

	return (
		<div className="dropdowns--server">
			<div className="params-header">
				<span>06</span>
				<h2>Server</h2>
			</div>
			<ServicesMultiplierDropdown
				handleNameOptionClick={(multiplier: BaseNameMultiplier) => {
					thunkDispatch(dispatchWithOrderPriceCalculation(setServer(multiplier), tiersPricing));
				}}
				value={value}
				options={paramPricing}
			/>
		</div>
	);
};

export default ParamsServer;
