import { RequestMetadata, RequestResponse } from '../apiConfig';
import { getRequest } from '../RequestFactory';

const GET_CHAMPIONS_LIST: RequestMetadata = {
	path: '/latest/plugins/rcp-be-lol-game-data/global/default/v1/champion-summary.json',
	method: 'GET',
	headers: {},
	shouldAuth: false,
	hasResponseBody: true,
	isDataServer: false,
	isLolApi: true,
};

type GetChampionsListResponseBody = any;

export const getChampionsList = async (): Promise<RequestResponse<GetChampionsListResponseBody>> => {
	return await getRequest<undefined, GetChampionsListResponseBody>({ ...GET_CHAMPIONS_LIST });
};

export default getChampionsList;
