import { APICoupon } from 'types/order';
import { RequestMetadata, RequestResponse } from '../../apiConfig';
import { getRequest } from '../../RequestFactory';

const GET_COUPON_BY_CODE: RequestMetadata = {
	path: '/Coupons/{code}',
	method: 'GET',
	headers: {},
	shouldAuth: false,
	hasResponseBody: true,
	isDataServer: false,
	isLolApi: false,
};

type getCouponByCodeResponseBody = APICoupon;

export const getCouponByCode = async (code: string): Promise<RequestResponse<getCouponByCodeResponseBody>> => {
	return await getRequest<undefined, getCouponByCodeResponseBody>(GET_COUPON_BY_CODE, {
		path: new Map<string, string>([['code', `${code}`]]),
	});
};

export default getCouponByCode;
