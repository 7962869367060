import React, { useState } from 'react';
import { useOutsideAlerter } from '../../../hooks/useOutsideAlerter';
import { MasterPoints } from '../../../redux/types/generalTypes';
import { BaseNameMultiplier, BaseRangeMultiplier, RequestResponseMultiplier } from '../../types/general';
import clsx from 'clsx';
interface IServicesMultiplierDropdownProps {
	options?: RequestResponseMultiplier;
	value?: string;
	handleRangeOptionClick?: (multiplier: BaseRangeMultiplier) => void;
	handleNameOptionClick?: (multiplier: BaseNameMultiplier) => void;
	mastersData?: MasterPoints;
}

const ServicesMultiplierDropdown: React.FC<IServicesMultiplierDropdownProps> = ({
	options,
	value,
	handleRangeOptionClick,
	handleNameOptionClick,
	mastersData,
}) => {
	const [isActive, setIsActive] = useState<boolean>(false);

	const renderOptions = () => {
		if (options && handleRangeOptionClick !== undefined) {
			let allOptions: JSX.Element[] = [];
			let optionsNameMultiplier = options as BaseRangeMultiplier[];
			optionsNameMultiplier.map((option, index) => {
				allOptions.push(
					<li
						key={option.range + index}
						onClick={() => {
							setIsActive(!isActive);
							handleRangeOptionClick(option);
						}}
					>
						{option.range}
					</li>,
				);
			});
			return allOptions;
		} else if (options && handleNameOptionClick) {
			let allOptions: JSX.Element[] = [];
			let optionsNameMultiplier = options as BaseNameMultiplier[];
			optionsNameMultiplier.map((option, index) => {
				allOptions.push(
					<li
						key={option.name + index}
						onClick={() => {
							setIsActive(!isActive);
							handleNameOptionClick(option);
						}}
					>
						{option.name.toLocaleUpperCase()}
					</li>,
				);
			});
			return allOptions;
		}
	};

	const wrapperRef = React.createRef<HTMLDivElement>();
	useOutsideAlerter(wrapperRef as React.MutableRefObject<HTMLDivElement>, () => setIsActive(false));

	return (
		<div ref={wrapperRef} className={clsx({ 'services-dropdown--masters': mastersData }, 'services-dropdown')}>
			<div onClick={() => setIsActive(!isActive)} className="services-dropdown__selected-option">
				<p>{mastersData ? mastersData.startingPoints : value}</p>
				{mastersData ? null : <span className="material-icons dropdown-icon">arrow_drop_down</span>}
			</div>
			<ul className={clsx({ active: isActive }, 'services-dropdown__options')}>{renderOptions()}</ul>
		</div>
	);
};

export default ServicesMultiplierDropdown;
